import React from "react";
import Footer from "../Footer";
import HeaderEl from "../HeaderEl";
import './style.scss'

import {disclaimerText, disclaimerTitle} from "../../config/copy";

import { siteRoot } from '../../config/config'

const Disclaimer = ({screenState, coverImageDataUrl, shareUrl,  introStartedOut}) => {
  const handleBack = () => {
    const referrer = document.referrer;
    const currentDomain = window.location.hostname;

    // check if there is a referrer and referrer domain, current domain are same
    if (referrer && referrer.includes(currentDomain)) {
      window.history.back();
    } else {
      window.location.href = siteRoot + '/';
    }
  };

  return (
    <main className="disclaimer_wrap">
      <div className="disclaimer">
        <div className="disclaimer_text">
          <div>
            <h1 className="disclaimer_title">
              {disclaimerTitle}
            </h1>
            <p>{disclaimerText}</p>
            {/* <p>{disclaimerText2}</p> */}

            <button className="disclaimer_back" onClick={handleBack}>Back</button>
          </div>
        </div>
      </div>

      <HeaderEl screenState={screenState} />
      <Footer screenState={screenState} coverImageDataUrl={coverImageDataUrl} shareUrl={shareUrl}/>

    </main>
  )
}

export default Disclaimer
