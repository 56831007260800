import React, { useState, useEffect, useRef } from 'react'
import Disclaimer from "../Disclaimer";
import Share from "../Share";

import Intro from '../Intro'

import InputHeader from '../InputHeader'
import HeaderEl from '../HeaderEl'
import Footer from '../Footer'

import MessageInput from '../MessageInput'
// import WebGLView from '../WebGLView'

import ErrorMessage from '../ErrorMessage'

import GeneratingIndicator from '../GeneratingIndicator'
import GenericLoader from '../GenericLoader'

import MessageOutput from '../MessageOutput'
import Button from '../Button'
import RecommendedBooks from '../RecommendedBooks'

import useGenerationRequest from '../../hooks/useGenerationRequest'

import './style.scss' // add after imports to apply overriding styles

import { debounce, getParameterByName, getPathValueFromUrl } from '../../utils/helpers'

import {
  subjectALabel,
  subjectBLabel,
  subjectAPlaceholder,
  subjectBPlaceholder,
  generateAnotherLabel,
  generateYourOwnLabel,
  enterButtonLabel,
  serverErrorMessage,
  contentPolcityErrorMessage,
  shareDataError,
  limitReachedMessage,
  placeholderItems
} from '../../config/copy'

import { siteRoot, siteRootProduction, resultsFolder, resultsThumbsFolder, siteShareRoot, siteRootBase, introDuration, apiEndpoint, numRecommendations } from '../../config/config'

import { ReactComponent as Heart } from '../../assets/and.svg'


import CoverOverlay from '../../assets/img/cover-overlay.png';
import CoverOverlayTexture from '../../assets/img/cover-overlay-texture.png';

import ReactGA from "react-ga4";
import { checkLimit } from '../../utils/attempts.js';



function App() {
  const shareUrlId = getPathValueFromUrl('/share/') || getParameterByName('share') || null

  const skipIntro = window.location.search.includes('skip')
  const allowExtra = window.location.search.includes('extra')

  const [screenState, setScreenState] = useState('intro') // skipIntro ? 'input' : 'intro')

  const [subjectBInput, setSubjectBInput] = useState('')
  const [subjectAInput, setSubjectAInput] = useState('')

  const [shareUrl, setShareUrl] = useState('')

  // const [messageRequest, setMessageRequest] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [introStartedOut, setIntroStartedOut] = useState(false)

  const getFullCoverImageUrl = (resultId, imageUrl, loadFromProduction) => {
    // if curCoverImageUrl doesn't start with http
    if (imageUrl && imageUrl.indexOf('http') !== 0 && imageUrl.indexOf('/') !== 0) {
      // prepend site root

      const curSiteRoot = loadFromProduction ? siteRootProduction : siteRoot;
      
      if (imageUrl.includes(resultId + '/')) {
        imageUrl = curSiteRoot + '/' + resultsFolder + '/' + imageUrl;
      } else {
        imageUrl = curSiteRoot + '/' + resultsFolder + '/' + resultId + '/' + imageUrl;
      }
    }
    return imageUrl;
  }

  const [bookItems, setBookItems] = useState([])
  // [
  //     {
  //       id: 'Cb96y18E',
  //       imageUrl: getFullCoverImageUrl('Cb96y18E', 'resn-romance_balloon-and-cactus_Cb96y18E.jpg'),
  //     },
  //     {
  //       id: 'A7thVgad',
  //       imageUrl: getFullCoverImageUrl('A7thVgad', 'resn-romance_fork-and-toaster_A7thVgad.jpg'),
  //     },
  //     {
  //       id: 'mTNFwnGn',
  //       imageUrl: getFullCoverImageUrl('mTNFwnGn', 'resn-romance_bob-the-builder-and-mary-poppins_mTNFwnGn.jpg'),
  //     },
  //   ]
  // )

  const componentMounted = useRef(false)

  const [hideName, setHideName] = useState(false)
  const [shareId, setShareId] = useState(shareUrlId || null)
  const [bookColor, setBookColor] = useState(null)

  const [error, setError] = useState(null)

  const [outputText, setOutputText] = useState('')
  const [responseData, setResponseData] = useState(null)

  // const [imageUrl, setImageUrl] = useState('')

  const [titleText, setTitleText] = useState('')
  const [authorText, setAuthorText] = useState('')
  const [taglineText, setTaglineText] = useState('')
  const [subjectsText, setSubjectsText] = useState('')

  const [placeholderItemsSetA, setPlaceholderItemsSetA] = useState(null)
  const [placeholderItemsSetB, setPlaceholderItemsSetB] = useState(null)

  // const [imageDataUrl, setImageDataUrl] = useState(null);
  const [coverImageDataUrl, setCoverImageDataUrl] = useState(null);

  const [hasInput, setHasInput] = useState(false)

  const subjectAInputRef = useRef(null)
  const subjectBInputRef = useRef(null)

  const [ hasReachedLimit, setHasReachedLimit] = useState(false);



  /**
   * Map screen state to a route name for analytics
   * @type {{input: string, displayOutput: string, intro: string, generating: string}}
   */
  const routesNames = {
    'intro': 'intro',
    'input': 'home',
    'generating': 'generating',
    'displayOutput': 'result',
    'disclaimer': 'disclaimer'
  };

  /**
   * Get route name from screen state, if it's been defined in routesNames object
   * @param screenState
   * @returns {*}
   */
  const getRouteName = (screenState) => {
    return routesNames.hasOwnProperty(screenState) ? routesNames[screenState] : screenState
  }

  /**
   * Send a pageview event for every screen state change
   */
  const handleAnalytics = () => {
    ReactGA.send({
      hitType: "pageview",
      page: screenState,
      title: getRouteName(screenState)
    });
  }

  const start = function() {
    setScreenState('input')
  }

  const disclaimer = function() {
    setScreenState('disclaimer')
  }

  const loadRecommendations = async function() {
    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'getRecommendations',
        count: numRecommendations,
        id: shareId || ''
      }),
    })

    try {
      const responseText = await response.text()
      const recommendations = JSON.parse(responseText)

      let newBookItems = []
      for (let i = 0; i < recommendations.length && i < numRecommendations; i++) {
        const bookId = recommendations[i].id
        const bookThumbUrl = siteRoot + '/' + resultsThumbsFolder + '/' + bookId + '.jpg'
        const newBookItem = {
          id: bookId,
          imageUrl: getFullCoverImageUrl(bookId, bookThumbUrl),
        }
        newBookItems.push(newBookItem)
      }
      setBookItems(newBookItems)
    } catch (error) {
      console.log(error)
    }
  }

  const loadShareResult = function(newShareId, loadFromProduction) {
    // load json file into response data from path with shareId
    const curSiteRoot = loadFromProduction ? siteRootProduction : siteRoot;
    const url = curSiteRoot + '/' + resultsFolder + '/' + newShareId + '/data.json';
    fetch(url)
      .then(response => response.json())
      .then(data => {
        data.isShareData = true;
        data.loadFromProduction = loadFromProduction;
        setResponseData(data)
        if (debugMode) {
          console.log('share data', data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setError(shareDataError)
      });
  }

  const retry = function() {
    ReactGA.event({
      category: 'Buttons',
      action: "create another",
      label: 'create another',
    });

    setOutputText('')
    setResponseData(null)

    setError(null)
    setInviteMode(false)

    setShareId(null)
    setBookColor(null)

    setScreenState('input')
    // setHideName(true)

    window.history.pushState({}, '', '/' + siteRootBase + '/' + window.location.search);

    setSubjectAInput('')
    setSubjectBInput('') // clear fear input to input different fear
  }

  const selectBook = function(bookItem) {
    setScreenState('loadingBook')

    loadShareResult(bookItem.id, true)

    loadRecommendations()
  }

  const debugMode = window.location.search.includes('debug')

  const storyMode = window.location.search.includes('story') // invite mode off by default

  const initialInviteMode = window.location.search.includes('invite') // invite mode off by default
  // const inviteMode = !window.location.search.includes('to') // invite mode on by default

  const [inviteMode, setInviteMode] = useState(initialInviteMode)

  const inviteName = getParameterByName('invite') || ''
  const storyName = getParameterByName('story') || ''

  const recipientName = getParameterByName('to') || '' // for non-invites

  // get properties from useChat hook
  const {
    // debugText,
    sendMessage,
    // messages,
  } = useGenerationRequest(subjectAInput, subjectBInput, setResponseData, setIsLoading, setError, debugMode)

  useEffect(() => {
    switch (screenState) {
      case 'input':
        setPlaceholderItems();
        break;
    }
  }, [screenState])

  useEffect(() => {
    switch (screenState) {
      case 'input':
        if (hasReachedLimit && error !== shareDataError) {
          setError(limitReachedMessage);
        }
        break;
      default:
        break;
    }
  }, [hasReachedLimit])

  const setPlaceholderItems = () => {
    // create ranomdised copy ofplaceholderItems
    let randomisedPlaceholderItems = [...placeholderItems].sort(() => Math.random() - 0.5)

    // split list
    let newPlaceholderItemsSetA = randomisedPlaceholderItems.slice(0, randomisedPlaceholderItems.length / 2)
    let newPlaceholderItemsSetB = randomisedPlaceholderItems.slice(randomisedPlaceholderItems.length / 2)

    setPlaceholderItemsSetA(newPlaceholderItemsSetA)
    setPlaceholderItemsSetB(newPlaceholderItemsSetB)
  }

  // set name if set with invite or recipient name url parameters
  useEffect(() => {
    if (inviteName && inviteName.length) {
      //captialise first letter
      const capitalisedName = inviteName.charAt(0).toUpperCase() + inviteName.slice(1)
      setSubjectAInput(capitalisedName)

      // setHideName(true)
    } else if (recipientName && recipientName.length) {
      //captialise first letter
      const capitalisedName = recipientName.charAt(0).toUpperCase() + recipientName.slice(1)
      setSubjectAInput(capitalisedName)

      // setHideName(true)
    } else if (storyName && storyName.length) {
      //captialise first letter
      const capitalisedName = storyName.charAt(0).toUpperCase() + storyName.slice(1)
      setSubjectAInput(capitalisedName)

      // setHideName(true)
    } else {
      // setHideName(false)
    }
  }, [inviteName, recipientName])

  // // on input changed
  // useEffect(() => {
  //   subjectBInputRef.current = subjectBInput // store input in ref to ensure latest value is used in delayed send button activate debounce
  // }, [subjectBInput])

  // on load
  useEffect(() => {
    // if component mounted is not set
    if (!componentMounted.current) {
      // set component mounted
      componentMounted.current = true

      if (window.location.pathname.includes('/disclaimer')) {
        disclaimer();
      } else if (shareId) {
        loadShareResult(shareId);
      } else {
        if (introDuration >= 0 && !skipIntro)
        {
          setTimeout(() => {
            start()
          }, introDuration * 1000)
        } else {
          start()
        }
      }

      loadRecommendations()
    }
  }, [])

  // on message input changed
  const handleInputChange = () => {
    let newHasInput = subjectAInput.trim().length > 0 && subjectAInput.trim().length > 0
    if (newHasInput) {
      // if input has text
      debounce(() => {
        // debounce to add delay to send button activation
        // const currentInput = subjectBInputRef.current
        const currentHasInput = subjectBInput.trim().length > 0
        setHasInput(currentHasInput) // activate send button if input still has text
      }, 250)()
    } else {
      // if input is empty
      setHasInput(newHasInput) // deactivate send button immediately
    }
  }

  // on inputs changed
  useEffect(() => {
    // form message request for analysis with name and worst fear
    // const newMessageRequestValue = "Name: " + subjectAInput + "\nWorst Fear: " + subjectBInput
    // const newMessageRequestValue = subjectAInput + " and " + subjectBInput
    // const newMessageRequestValue = "A Christmas Romance between \'" + subjectAInput + "\' and \'" + subjectBInput + "\'"

    // const newMessageRequestValue = "A Christmas Romance between: \'" + subjectAInput + " & " + subjectBInput + "\'"
    // setMessageRequest(newMessageRequestValue)

    handleInputChange() // handle message subjectBInput change for activating send button
  }, [subjectAInput, subjectBInput])

  const focusNextInput = event => {
    subjectBInputRef.current.focus()
  }

  const handleInputFocus = () => {

    switch (screenState) {
      case 'input':
        // if going to enter fear state, name and message input has been set
        if (subjectAInput) {
          if (!error) { // if there is no error
            // subjectBInputRef.current.focusAndSelect()
            subjectAInputRef.current.focus()
          } else {
            subjectBInputRef.current.focus()
          }
        } else {
          // don't focus to allow user to see examples
          // if (!hideName && subjectAInputRef.current) {
          //   subjectAInputRef.current.focus()
          // }
        }
        break
      default:
        break
    }
  }

  /**
   * Handles attempts based on the current screen state.
   * - increment the tries if we are on the displayOutput screen.
   * - check the limit if we are on the input or displayOutput screen. This accounts
   * for the case where the user has reached the limit and then refreshes the page,
   * as well as the results page itself.
   *
   * @returns {void}
   */
  const handleAttempts = () => {
    if (['input','displayOutput' ].includes(screenState)) {
      setHasReachedLimit(checkLimit() && !allowExtra);
    }
  }

  /**
   * Handle changing Screenstate
   */
  useEffect(() => {
    handleAnalytics();
    handleInputFocus();
    handleAttempts();
  }, [screenState, hideName])

  // handle sending message and hiding conversations logged warning
  const handleSubmit = () => {
    if (subjectBInput && subjectAInput) {
      if (!checkLimit() || allowExtra) {
        sendMessage()
      } else {
        setError(null)

        setTimeout(() => {
          setError(limitReachedMessage)
        }, 100)
      }
    }
  }

  // go to enter fear state on error
  useEffect(() => {
    if (error) {
      setScreenState('input')
    }
  }, [error])

  // // go to displayOutput state when output text is set
  // useEffect(() => {
  //   if (outputText) {
  //     setScreenState('displayOutput')
  //   }
  // }, [outputText])

  // go to displayOutput state when output text is set
  useEffect(() => {
    if (!responseData) {
      return;
    }

    const isMissingData = !responseData.title
      || !responseData.id
      || !responseData.author
      || !responseData.tagline
      || !responseData.blurb
      || !responseData.subjects
      || !responseData.coverImageUrl;

    if (isMissingData) {
      const missingKeys = ["id", "title", "author", "tagline", "blurb", "subjects", "coverImageUrl"].filter(key => !responseData[key])
      if (debugMode) {
        console.error('Missing response data:', missingKeys)
      }
      if (responseData.isShareData) {
        setError(shareDataError)
      } else {
        if (responseData.title === ''
          && responseData.author === ''
          && responseData.author === ''
          && responseData.blurb === '') {
          setError(contentPolcityErrorMessage) // if no data, likely gpt response from inappropriate content
        } else {
          setError(serverErrorMessage)
        }
      }
      return;
    }

    setTitleText(responseData.title || "")
    setAuthorText(responseData.author || "")
    setTaglineText(responseData.tagline || "")
    setOutputText(responseData.blurb || "")
    setSubjectsText(responseData.subjects || "")
    setBookColor(responseData.color || null)

    // setBookColor("red")
    // setBookColor("magenta")
    // setBookColor("green")
    // setBookColor("light-green")
    // setBookColor("orange")
    // setBookColor("purple")
    // setBookColor("teal")

    // setImageUrl(responseData.imageUrl || "")

    // let curImageUrl = responseData.imageUrl;
    // // if curImageUrl doesn't start with http
    // if (curImageUrl && curImageUrl.indexOf('http') !== 0 && curImageUrl.indexOf('/') !== 0) {
    //   // prepend site root
    //   curImageUrl = siteRoot + '/' + resultsFolder + '/' + curImageUrl;
    // }

    // const image = new Image();
    // image.onload = () => {
    //   setImageDataUrl(curImageUrl);
    //   // setScreenState('displayOutput');
    // };
    // image.onerror = () => {
    //   setError(serverErrorMessage)
    // };
    // image.src = curImageUrl;

    let resultId = responseData.id;

    let curCoverImageUrl = getFullCoverImageUrl(resultId, responseData.coverImageUrl, responseData.loadFromProduction)

    const coverImage = new Image();
    coverImage.onload = () => {
      setCoverImageDataUrl(curCoverImageUrl);
      setScreenState('displayOutput');
    };
    coverImage.onerror = (error) => {
      if (debugMode) {
        console.error('error loading cover image', error);
      }
      setError(serverErrorMessage)
    };
    coverImage.src = curCoverImageUrl;

    if (resultId && resultId !== 'test') {
      setSharePath('/share/' + resultId);
    }

  }, [responseData])

  const setSharePath = (sharePath) => {
    window.history.pushState({}, '', '/' + siteRootBase + sharePath + window.location.search);
    let newSiteShareUrl = siteShareRoot + sharePath;
    if (newSiteShareUrl.indexOf('http') !== 0) {
      newSiteShareUrl = 'https://' + newSiteShareUrl;
    }
    setShareUrl(newSiteShareUrl);
  }

  // go to generating
  useEffect(() => {
    if (isLoading) {
      setScreenState('generating')
    }
  }, [isLoading])

  // Using an effect to check for URL change

  useEffect(() => {
    if (window.location.pathname.includes('/disclaimer')) {
      setScreenState('disclaimer');  // set the state to 'disclaimer'
    }
  }, []);


  const getInputScreen = () => {
    return <div className="input-screen">
      <InputHeader/>
      <div className="input-screen_label">{subjectALabel}</div>
      <MessageInput
        input={subjectAInput}
        // placeholderText={subjectAPlaceholder}
        placeholderItems={placeholderItemsSetA}
        onInputChange={value => {
          setSubjectAInput(value)
        }}
        onEnterKeyDown={focusNextInput}
        ref={subjectAInputRef}
      />
      <div className="app_heart app_and"><Heart></Heart></div>
      <div className="input-screen_label">{subjectBLabel}</div>
      <MessageInput
        input={subjectBInput}
        // placeholderText={subjectBPlaceholder}
        placeholderItems={placeholderItemsSetB}
        hasInput={hasInput}
        hasSubmit={true}
        carouselDelayOffset={0.5}
        onInputChange={value => {
          setSubjectBInput(value)
        }}
        onSend={handleSubmit}
        ref={subjectBInputRef}
      />
      <div className="app_enter-button-container">
        {error ? <ErrorMessage
          errorMessage={error}
        /> : null}
        <button
          className={`enter-button`}
          onClick={handleSubmit}
          data-has-input={hasInput ? true : null}
        >
          {enterButtonLabel}
        </button>
      </div>
    </div>;
  }

  const getResult = () => {
    return <div className="output-screen">
      <div className='output-screen__block-container'>
        <div className='output-screen__block output-screen__block-left'>
          <div className='output-screen__block-content output-screen__block-content-left'>
            <div className='output-screen__cover-container'>
              <div className='output-screen__cover-content-container'>
                {/* {imageDataUrl && (
                  <img className='output-screen__cover-content-image' src={imageDataUrl} alt=""/>
                )} */}
                <img src={coverImageDataUrl} className='output-screen__cover-overlay-container-cover' alt=""/>
              </div>
              <div className='output-screen__cover-overlay-container output-screen__cover-overlay-container-components'>
                <img src={CoverOverlay} alt=""/>
                <div className='output-screen__cover-overlay-text output-screen__cover-overlay-title'>{titleText}</div>
                <div
                  className='output-screen__cover-overlay-text output-screen__cover-overlay-author'>by {authorText}</div>
                <div
                  className='output-screen__cover-overlay-text output-screen__cover-overlay-tagline'>{taglineText}</div>
                <div
                  className='output-screen__cover-overlay-text output-screen__cover-overlay-starring'>Starring {subjectsText}</div>
                <img src={CoverOverlayTexture} className='output-screen__cover-overlay-container-texture' alt=""/>
              </div>
            </div>
          </div>
          {coverImageDataUrl && (<Share shareURL={shareUrl} imageURL={coverImageDataUrl} className="share--mobile" />)}
        </div>
        <div className='output-screen__block output-screen__block-right'>
          <div className='output-screen__block-content output-screen__block-content--text'>
            <div className='output-screen__right-spacer'></div>
            <div className='output-screen__right-wrap'>
              <MessageOutput
                titleText={titleText}
                outputText={outputText}
              />
              {hasReachedLimit ? '' :
                <div className='output-screen__rsvp-option-container rsvp-container'><Button
                  buttonLabel={shareId ? generateYourOwnLabel : generateAnotherLabel}
                  className="output-screen__rsvp-option yes"
                  onClick={() => retry()}
                /></div>
              }
            </div>
            { bookItems && bookItems.length ? <RecommendedBooks bookItems={bookItems} selectBook={selectBook} /> : null }
          </div>
        </div>
      </div>
    </div>;
  }



  // Check if the  screenState is disclaimer
  if (screenState === 'disclaimer') {
    return <Disclaimer screenState={screenState} coverImageDataUrl={coverImageDataUrl} shareUrl={shareUrl} />
  }

  return (
    <div className="app">
      {/* <WebGLView></WebGLView> */}

      <div className={`page-content ${bookColor ? 'book-theme-color-' + bookColor : ''}`}>
        {/* {debugMode ? <div className="debug-text">{debugText}</div> : null} */}
        { screenState === 'intro' && <div className="intro-screen">
          <Intro setIntroStartedOut={setIntroStartedOut} />
        </div> }

        {/* Inputs */}
        { screenState === 'input' && getInputScreen()}

        {/* Generating indicator */}
        { screenState === 'generating' && <GeneratingIndicator />}

        {/* Output screen */}
        { screenState === 'displayOutput' && getResult() }

        {/* Book loader */}
        { screenState === 'loadingBook' && <GenericLoader />}

        <HeaderEl screenState={screenState} introStartedOut={introStartedOut} />
        <Footer screenState={screenState} coverImageDataUrl={coverImageDataUrl} shareUrl={shareUrl}/>

      </div>
    </div>
  )
}

export default App
