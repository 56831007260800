// src/config/testResponseData.js

import TestImage from '../assets/img/test-cover.jpg';

export const testResponse = {
  "id": "2100ce5f6610",
  "title": "Crimson Spores Affair",
  "author": "Randy Caprice",
  "tagline": "Hearts entwined in seasonal spices",
  "blurb": "In the town of Verdant Valley, Rebecca Rhubarb, with her lush red stalks and razor-sharp green leaves, had always been the star of the Christmas farmers' market. Until one frosty December, Mitch Mushroom, a brooding portobello with a velvety cap, arrived, stirring up unexpected passion beneath the twinkling festive lights. Their attraction was undeniable, a spicy mix of sweet and earthy, drawing them together in a dance of desire among mistletoe and holly. But as the holiday cheer heightened, so did the drama, with both vying for the title of 'the season's most beloved dish', threatening to tear their newfound love apart.",
  "coverImageUrl": TestImage,
  "subjects": "Rebecca Rhubarb & Mitch Mushroom"
};