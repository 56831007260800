import React, { useRef } from 'react'
import './style.scss'

import { inputHeaderText } from "../../config/copy";

const InputHeader = ({ }) => {

  return (
    <div className="input-header__text">
      <div>
        <div className="input-header__title-prompt">{inputHeaderText}</div>
      </div>
    </div>
  )
}

export default InputHeader
