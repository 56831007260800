// src/useChat.js
import { useRef, useState, useEffect, useCallback } from 'react'

import { apiEndpoint } from '../config/config'

import { testResponse } from '../config/testResponseData'

import { serverErrorMessage, contentPolcityErrorMessage, serverRejectionErrorMessage, serverTooBusyErrorMessage } from '../config/copy'

import { incrementTries } from '../utils/attempts.js';

const useGenerationRequest = (subjectAInput, subjectBInput, setResponseData, setIsLoading, setError, debugMode) => {
  // requestedBots, botUserPrefs, setBotUserPrefs

  // allow override of default gpt-4 model by specifiying gpt-3.5 in the url e.g. /?gpt-3.5
  // const providedModel = window.location.search.includes('gpt-4')
  //   ? 'gpt-4'
  //   : window.location.search.includes('gpt-3.5')
  //   ? 'gpt-3.5'
  //   : 'gpt-4'
  // const [model] = useState(providedModel) // OpenAI model (based provided url param or default)
  // const [curModel, setCurModel] = useState(providedModel) // current OpenAI model from server (may fallback if credit used)

  // GPT chat completion options
  // see reference for explainations: https://platform.openai.com/docs/api-reference/chat/create
  // const [gptOptions, setGptOptions] = useState({
  //   // temperature: 0,         // sampling temperature (chaos) - number, default = 1 - higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic - recommended altering this or top_p but not both
  //   // top_p: 0,               // nucleus sampling - number, default = 1 - An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered - recommended altering this or temperature but not both
  //   // n: 1,                   // chat completion choices to generate - integer, default = 1
  //   // stop: null,             // stop sequences - string or array, default = null - Up to 4 sequences where the API will stop generating further tokens
  //   // max_tokens: 100,        // max tokens to generate - integer, default = infinity
  //   // presence_penalty: 0,    // presence penalty - number between -2.0 and 2.0, default = 0 - Positive values penalize new tokens based on whether they appear in the text so far
  //   // frequency_penalty: 0,   // frequency penalty - number between -2.0 and 2.0, default = 0 - Positive values penalize new tokens based on their existing frequency in the text so far
  //   // logit_bias: {104: -1},  // logit bias - object, default = null - JSON object that maps tokens (specified by their token ID in the tokenizer) to an associated bias value from -100 to 100
  // })

  // const [messages, setMessages] = useState([])
  
  // const [debugText, setDebugText] = useState('')

  const hasLoaded = useRef(false)

  const skipGeneration = window.location.search.includes('skip')
  const lowQuality = window.location.search.includes('low')

  // send message to server and get OpenAI response
  const sendMessage = async () => {
    if (skipGeneration)
    {
      setResponseData(testResponse)
      return
    }

    // let curInput = messageRequest

    setError(null) // clear error so error message is shown again if error occurs
    setIsLoading(true)

    if (subjectAInput.trim() !== '' && subjectBInput.trim() !== '') {
      // const inputMessage = curInput.trim()
      const subjectAString = subjectAInput.trim()
      const subjectBString = subjectBInput.trim()

      // add user message to chat view immediately
      // const userMessage = { role: 'user', appended: true, content: subjectAString + ', ' + subjectBString }

      // // add pending assistant loader to chat view
      // setMessages(prevMessages => {
      //   return [
      //     ...prevMessages,
      //     userMessage,
      //     { role: 'assistant', appended: true, isPending: true, content: '' },
      //   ]
      // })

      // clear input immediately
      // setInput('')

      let responseText = null

      // error variables
      let sendError = false
      let sendErrorUserMsg = null
      let sendErrorMsg = null

      // sendMessage request arguments
      const body = {
        action: 'generateResult',
        subjectA: subjectAString,
        subjectB: subjectBString,
        // message: inputMessage,
        // model: model, // requested gpt model
        // inviteMode: inviteMode,
        // storyMode: storyMode,
      }

      if (lowQuality) {
        body.lowQuality = lowQuality
      }

      // if (gptOptions) {
      //   body.gptOptions = gptOptions // gpt chat completion options
      // }

      // if (curSystemPrompt) {
      //   body.systemPrompt = curSystemPrompt // send system prompt if set
      // }

      if (debugMode) {
        // request debug info from server
        body.debug = debugMode // TODO: remove - for testing only
      }

      try {
        // send sendMessage request to server
        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })

        responseText = await response.text()
        const data = JSON.parse(responseText)

        if (debugMode) {
          // log response data for debugging if in debug mode
          console.log('SendMessage RESPONSE data:', data)

          // let errorString = data.debugString
          // if (data.debugError) {
          //   errorString = data.debugError + '\n' + errorString
          // }
          // setDebugText(errorString)
        }

        // if no error
        if (!data.error) {
          if (debugMode && data.usage) {
            // log OpenAI tokens used by request if in debug mode
            // maximum amount of characters to send to OpenAI is already calculated on the backend to avoid reaching limit
            // console.log(
            //   'transaction tokens: ',
            //   data.usage.total_tokens +
            //     ' / 8192 (' +
            //     Math.round((data.usage.total_tokens / 8192) * 100) +
            //     '%)'
            // )
          }

          let message_output = data.message

          // console.log("message_output", message_output)

          // if message_output is a string rather than an object
          if (typeof message_output === 'string') {
          // if message_output is not json then error
          // if (message_output && message_output[0] !== '{') {
            sendError = true
            sendErrorUserMsg = null; // debugMode ? 'invalid json data returned' : null
          }

          if (!message_output) {
            sendError = true
            sendErrorUserMsg = null; // debugMode ? 'no message returned' : null
          } else {
            // get blurb from message_output json object
            let responseData = message_output
            // console.log("responseData", responseData)

            // let blurbText = responseData.blurb
            // console.log("blurbText", blurbText)

            incrementTries();
            setResponseData(responseData)
            // setOutputText(blurbText)

            // setMessages(prevMessages => [
            //   // remove any bot pending messages
            //   ...prevMessages.filter(message => !message.isPending),

            //   // add bot's response
            //   { role: 'assistant', appended: true, content: blurbText },
            // ])
          }
        } else {
          sendError = true
          sendErrorUserMsg = null; // data.error
          sendErrorMsg = debugMode && data.debugError ? ' ' + data.debugError : null // data.error +

          // console.log('sendMessage data ', data)

          switch (data.errorName) {
            case 'content_policy_violation':
              sendErrorUserMsg = contentPolcityErrorMessage
              break
            case 'openai_rejection':
              sendErrorUserMsg = serverRejectionErrorMessage
              break
            case 'too_many_requests':
              sendErrorUserMsg = serverTooBusyErrorMessage
              break
          }
          // console.log('sendErrorUserMsg', sendErrorUserMsg)
        }
      } catch (error) {
        console.error(error)
        sendError = true
        sendErrorMsg = debugMode ? ' ' + error : null
      }

      setIsLoading(false)

      // if error has occured
      if (sendError) {
        let errorMessage = serverErrorMessage // 'An error occurred while attempting to send message. Please try again.'

        if (sendErrorUserMsg) {
          // use custom error message from server if provided
          errorMessage = sendErrorUserMsg
        }
        
        setError(errorMessage)

        // console.log('setError', errorMessage)

        // setMessages(prevMessages => [
        //   // remove pending messages
        //   // and remove last sent message
        //   ...prevMessages.filter(message => !message.isPending).slice(0, -1),

        //   // display error message
        //   { role: 'error', appended: true, content: errorMessage },
        // ])

        // restore previous input to allow user to retry
        // setSubjectAInput(subjectAInput)
        // setSubjectBInput(subjectAInput)

      }
    }
  }

  // // clear messages and input
  // const clearMessages = () => {
  //   setMessages([])
  //   // setInput('')
  // }

  return {
    // messages,
    sendMessage,
    // clearMessages,
  }
}

export default useGenerationRequest
