// src/MessageInput.js
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import './style.scss'

import TextCarousel from '../TextCarousel'

import { isMobile } from '../../config/config'

const MessageInput = forwardRef(({ placeholderText, input, hasSubmit, hasInput, onInputChange, onKeyDown, onEnterKeyDown, onSend, placeholderItems, carouselDelayOffset }, ref) => {
  const textareaRef = useRef()

  // expose the focus method to parent components
  useImperativeHandle(ref, () => ({
    focus: () => {
      textareaRef.current.focus();
    },
    focusAndSelect: () => {
      textareaRef.current.focus()
      textareaRef.current.setSelectionRange(0, textareaRef.current.value.length)
    }
  }));

  const [allowSelection, setAllowSelection] = useState(true) // allow selection of text in input field (toggled off when not focused to allow select all to avoid selecting input placeholder text)
  const [inputFocused, setInputFocused] = useState(false) // allow selection of text in input field (toggled off when not focused to allow select all to avoid selecting input placeholder text)

  // auto grow textarea height
  // useEffect(() => {
  //   textareaRef.current.style.height = 'auto'
  //   textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px'
  // }, [input])

  // prevent input placeholder text being selectable when text input is not focused
  const handleFocus = () => {
    setAllowSelection(true)
    setInputFocused(true)
  }
  const handleBlur = () => {
    setAllowSelection(false)
    setInputFocused(false)
  }

  // handle message input key down event
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (isMobile) {
        // if mobile
        event.preventDefault()
        if (onEnterKeyDown) {
          onEnterKeyDown();
        }
        if (hasSubmit) {
          onSend() // send message
        }
      } else {
        // is desktop
        if (!event.shiftKey) {
          // if shift key is not pressed
          event.preventDefault()
          if (onEnterKeyDown) {
            onEnterKeyDown();
          }
          if (hasSubmit) {
            onSend() // send message
          }
        }
      }
    }
  }

  return (
    <div className="container">
      {/* <div className="inputLabel">Your deepest fear:</div> */}
      <div className={`message-input ${hasSubmit ? 'has-submit-button' : ''}`} data-disable-selection={!allowSelection ? true : null}>
        { placeholderItems && placeholderItems.length ? <div className={`message-input_placeholder-container ${!input && !inputFocused ? 'message-input_placeholder-active' : ''}`}>
          <TextCarousel textTimes={placeholderItems} carouselDelayOffset={carouselDelayOffset} />
        </div> : null }
        <input
          type="text"
          className="message-input__textarea"
          ref={textareaRef}
          value={input}
          rows={1}
          onChange={e => onInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholderText}
          // autoFocus={true}
        />
        {/* { hasSubmit ? <button
          className={`message-input__button`}
          onClick={onSend}
          data-has-input={hasInput ? true : null}
        >
          { enterButtonLabel }
        </button> : null } */}
      </div>
    </div>
  )
})

export default MessageInput
