// src/config/config.js

import { serverUrl } from './api-config';

export const isMobile = /Mobile/i.test(navigator.userAgent);

export const isProduction = process.env.NODE_ENV === 'production';
export const siteRoot = serverUrl; // address to access (considering sub folder locally and online)
// export const siteRoot = isProduction ? '' : serverUrl; // address to access from local development
export const apiEndpoint = siteRoot + '/api/api.php';

export const siteRootProduction = siteRoot.includes('xmas-2023-staging') ? siteRoot.replace('xmas-2023-staging', 'xmas-2023') : siteRoot;

export const siteRootBase = 'xmas-2023';
export const siteShareRoot = siteRoot.includes('-staging') ? siteRoot : 'xmas.resn.co.nz';

export const resultsFolder = 'results';
export const resultsThumbsFolder = 'results-thumbs';

export const introDuration = 5;
export const introOutDuration = 0.75;
export const introStartedOutOffset = 0.2;
export const introOutStartOffset = -0.05;

export const numRecommendations = 3;

const contactEmailSubject = 'AI Holiday Romance';
// const contactEmailBody = 'I saw your Resn Holiday Romance site and I want to talk about our next AI project...';

export const resnSiteLink = 'https://resn.co.nz';
export const contactUrl = `mailto:newbusiness@resn.co.nz?subject=${encodeURIComponent(contactEmailSubject)}`; // &body=${contactEmailBody}`;

export const limitCount = 5;
export const lockTime = 3600000; // one hour in milliseconds
