/**
 * @module src/utils/attempts
 * This utility contains functions that are used to limit the number of user tries to a
 * number defined in config.js.
 */

import {limitCount, lockTime} from "../config/config";

/**
 * Retrieves the number of tries from localStorage.
 * @returns {number|number}
 */
export const getTries = () => {
  let storedTries = parseInt(localStorage.getItem("tries"), 10);
  return isNaN(storedTries) ? 0 : storedTries;
};

/**
 * Retrieves the time of the last try from localStorage.
 * @returns {Date|null}
 */
export const getLastTryTime = () => {
  let storedTime = parseInt(localStorage.getItem("lastTime"));
  return isNaN(storedTime) ? null : storedTime;
};

/**
 * Saves the number of tries to the local storage.
 *
 * @param {number} tries - The number of tries to save.
 */
export const saveTries = (tries) => {
  localStorage.setItem("tries", tries.toString());
};

/**
 * Saves the last try time to local storage.
 *
 * @param {Date} time - The time to be saved.
 * @returns {void}
 */
export const saveLastTryTime = (time) => {
  if (!time) {
    return;
  }
  localStorage.setItem("lastTime", time.toString());
};


/**
 * Increments the number of tries, saves the updated value, and saves the current time of the try.
 *
 * This function retrieves the current number of tries by calling `getTries` function,
 * increments it by 1, saves the updated value by calling `saveTries` function,
 * and saves the current time by calling `saveLastTryTime` function.
 *
 * Used on teh results screen
 *
 * @returns {void}
 */
export const incrementTries = () => {
  let now = Date.now();
  let newTries = getTries() + 1;
  saveTries(newTries);
  saveLastTryTime(now);
}

export const resetTries = () => {
  localStorage.removeItem("tries");
  localStorage.removeItem("lastTime");
}

/**
 * Checks if the limit has been reached based on the number of tries and the time elapsed since the last try.
 *
 * @returns {boolean} true if the limit has been reached, false otherwise
 */
export const checkLimit = () => {
  const lastTryTime = getLastTryTime();
  if (!lastTryTime) {
    return false;
  }

  const now = Date.now();
  const diff = now - lastTryTime;
  const limitReached = getTries() >= limitCount;
  const attemptsExpired =  diff > lockTime;

  // handle case where tries have been reached and are not expired
  if (limitReached && diff < lockTime) {
    return true;
  }

  // reset Tries to 0 if last try was more than our reset window (lockTime) ago, but limit reached
  if (limitReached || attemptsExpired) {
    resetTries();
  }

  return false;
}
