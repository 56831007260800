import React, { useRef, useState, useEffect } from 'react'
import './style.scss'

import { ReactComponent as IntroLogo } from '../../assets/intro-logo.svg'
import { ReactComponent as IntroByResn } from '../../assets/intro-by-resn.svg'

import { introDuration, introOutDuration, introOutStartOffset, introStartedOutOffset } from '../../config/config'

const skipIntro = window.location.search.includes('skip')

const Intro = ({ setIntroStartedOut }) => {

  const componentMounted = useRef(false)

  // animate out state
  const [animateOut, setAnimateOut] = useState(false)

  // on load
  useEffect(() => {
    // if component mounted is not set
    if (!componentMounted.current) {
      // set component mounted
      componentMounted.current = true

      let introAnimOutTime = null;
      
      if (introDuration >= 0 && !skipIntro) {
        // console.log('setAnimateOut', (introDuration - introOutDuration) * 1000)
        introAnimOutTime = setTimeout(() => {
          setAnimateOut(true)
        }, (introDuration - introOutDuration + introOutStartOffset) * 1000)

        introAnimOutTime = setTimeout(() => {
          setIntroStartedOut(true)
        }, (introDuration - introOutDuration + introStartedOutOffset) * 1000)
      }

      // return () => {
      //   clearTimeout(introAnimOutTime)
      // }
    }
  }, [])

  return (
    <div className="intro">
      <div className={`intro-bg-reveal ${animateOut ? 'intro-bg-reveal_hide' : ''}`}></div>
      <div className="intro_logo-container">
        <div className="intro_logo">
          <div className="intro_logo-offset-container">
            <div className="intro_logo-graphic">
              <IntroLogo></IntroLogo>
            </div>
            <div className="intro_by-resn">
              <IntroByResn></IntroByResn>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
