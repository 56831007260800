import React, { useState } from 'react';
import ReactGA from "react-ga4";

/**
 * Copies the provided URL to the clipboard.
 *
 * @param {string} url - The URL to be copied to the clipboard.
 * @returns {JSX.Element} - A button element that, when clicked, copies the URL to the clipboard.
 */
const CopyButton = ({ url }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleGAEvent = (action) => {
    ReactGA.event({
      category: 'share',
      action: action,
      label: 'Share',
    });
  }

  /**
   * Handles button click event to copy url to clipboard
   *
   * @param {string} url - the url to copy
   */
  const handleCopyClick = async (url) => {
    handleGAEvent('copy link');
    try {
      await navigator.clipboard.writeText(url);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false)
        // unfocus button
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }, 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <button onClick={() => handleCopyClick(url)} className={isCopied ? "share_copy share_copy--isdone" : "share_copy"}>
      <span className="share_copytext-done">Copied!</span>
      <span className="share_copytext-link">Copy Link</span>
    </button>
  );
}

export default CopyButton;
