import React from 'react'
import './style.scss'
import Share from "../Share";

import { siteRootBase } from '../../config/config'

import { disclaimerButtonLabel } from '../../config/copy'

const Footer = ({screenState, coverImageDataUrl, shareUrl}) => {

  const footerClass = screenState ? `footer footer--${screenState}` : `footer`
  const isOnDisclaimerPage = screenState === 'disclaimer'
  const isResult = screenState === 'displayOutput'
  const showShare = isResult && coverImageDataUrl

  return (
    <footer className={footerClass}>
      <div className="footer__wrap">
        <div className="footer__left">
          {showShare && (<Share className="share--desktop" shareURL={shareUrl} imageURL={coverImageDataUrl}/>)}
          {!isOnDisclaimerPage ? <a className="footer_disclaimer-button"
                                    href={"/" + siteRootBase + "/disclaimer"}>{disclaimerButtonLabel}</a> : ''}
        </div>
      </div>

    </footer>
  )
}

export default Footer
